import tagmanager from '../../abstracts/tagmanager.js';
import {
  getSearchParametersFromObject,
  replacePathSegment,
  redirectTo,
} from '../../abstracts/url.js';

const filterList = (filters, sort, country, federalState, page) => ({
  filters: filters,
  sort: sort,
  page: page,

  country: country,
  federalState: federalState,

  syncing: false,

  init() {
    const watchAndExecute = (property, callback) => {
      this.$watch(property, (newValue, oldValue) =>
        this.executeOnChange(newValue, oldValue, callback),
      );
    };

    watchAndExecute('sort', () => this.gotoPage(1));
    watchAndExecute('filters.search', () => this.gotoPage(1));

    watchAndExecute('filters.countryOrState', (value) =>
      this.filterNavigationSelected('locationEntryPoint', value, 1),
    );
    watchAndExecute('filters.region', (value) => this.filterNavigationSelected('region', value, 1));
    watchAndExecute('filters.typeOrCategory', (value) =>
      this.filterNavigationSelected('typeOrCategory', value, 3),
    );
  },

  gotoPage(page) {
    this.page = page === 1 ? null : page;

    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.syncState();
  },

  applyFilter(details) {
    this.trackEvent(details.initiator ? 'comp_quick-filter_submit' : 'comp_filter_submit');

    this.gotoPage(1);
  },

  filterOpened(filterName) {
    this.trackEvent(filterName ? 'comp_quick-filter_open' : 'comp_filter_open', filterName);
  },

  executeOnChange(newValue, oldValue, callback) {
    newValue = newValue instanceof Object ? newValue.value : newValue;
    oldValue = oldValue instanceof Object ? oldValue.value : oldValue;

    if (oldValue !== newValue) {
      callback(newValue);
    }
  },

  trackEvent(event, selection) {
    tagmanager.event(event, {
      Category: 'tours',
      Selection: selection ?? false,
      Country: this.country,
      Federalstate: this.federalState,
    });
  },

  async syncState() {
    this.syncing = true;

    const url = this.getUrl();

    try {
      const response = await fetch(url, { method: 'POST' });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      history.replaceState(null, null, url);
      this.updateUI(data);
    } catch (error) {
      console.error('Failed to sync state:', error);

      window.location.reload();
    } finally {
      this.syncing = false;
    }
  },

  updateUI(data) {
    this.$refs.tours.innerHTML = data.tours;
    this.$refs.pagination.innerHTML = data.pagination;
    this.$refs.total.innerHTML = data.total;
  },

  filterNavigationSelected(navigationType, nameAlias, pathSegmentIndex) {
    this.trackEvent('comp_quick-filter_close', navigationType);
    redirectTo(replacePathSegment(pathSegmentIndex, nameAlias), ['filters', 'sort']);
  },

  getUrl() {
    let searchParams = getSearchParametersFromObject({
      filters: {
        search: this.filters.search,
        difficulty: this.filters.difficulty,
        distance: this.filters.distance,
        duration: this.filters.duration,
        elevation: this.filters.elevation,
      },
      sort: this.sort,
      page: this.page,
    });

    return (
      location.protocol +
      '//' +
      location.host +
      location.pathname +
      (searchParams.toString() ? '?' + searchParams.toString() : '')
    );
  },
});

export default filterList;
